import React from "react";
import { Routes, Route } from "react-router-dom";

import MainPage from "./Pages/Main/MainPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
// import NewsPage from "./Pages/News/Index/NewsPage";
// import NewsDetailsPage from "./Pages/News/Details/NewsDetailsPage";

function App() {
  return (
    <Routes>
      <Route path="/" Component={MainPage} />
      <Route path="privacy-policy" Component={PrivacyPolicy} />
      {/* <Route path="/News">
        <Route index Component={NewsPage} />
        <Route path=":newsId" Component={NewsDetailsPage} />
      </Route> */}
    </Routes>
  );
}

export default App;
